import FirstScreen from "~/components/Enga/Components/FirstScreen";
import AutopromoContainer from "~/components/Enga/Components/AutopromoContainer";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";
import imgProxy from "~/utils/imgproxy";
import { urlRs } from "~/utils/url";

import "./EngaPages.css";
import "./EngaSinglePage.css";

import IconPlayArrow from "~/img/icons/play_arrow.svg";
import IconArrowForward from "~/img/icons/arrow_forward.svg";

export default function Enga4Page() {
  return (
    <>
      <article class="page-engagement page-engagement-4">
        <FirstScreen
          visual="/images/engagements/page-engagement-4-visual.jpg"
          visualMobile="/images/engagements/page-engagement-4-visual-mobile.jpg"
          title="Des aménagements intelligents"
          prevLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/le-confort-d-ete.html"
          nextLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/des-espaces-exterieurs-vegetalises.html"
        />

        <EngaDetails />
        <section class="engagement-rows">
          <h2>Les preuves de nos engagements</h2>
          <EngaRow1 />
          <EngaRow2 />
        </section>
        <VideoExcerpt />

        <section class="next-nav">
          <a
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/des-espaces-exterieurs-vegetalises.html",
            )}
            class="btn"
          >
            Voir l'engagement suivant{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </section>
      </article>

      <AutopromoContainer />
    </>
  );
}

function EngaDetails() {
  return (
    <>
      <section class="engagement-details">
        <div class="introduction">
          <div class="tags">
            <span>Pour votre bien être</span>
          </div>
          <p>
            Construire des biens qui font du bien, c’est vous proposer des
            logements flexibles et divisibles pour les plus grands, et des
            aménagements adaptés au télétravail dans 100% de nos appartements.
          </p>
        </div>

        <div class="media-part infography">
          <img
            src="/images/engagements/page-engagement-4-animation.gif"
            alt=""
            height="720"
            width="1280"
            loading="lazy"
          />
        </div>
      </section>
    </>
  );
}

function EngaRow1() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>Des logements flexibles</h3>
          <p>
            Tous nos plans de logements sont systématiquement contrôlés par une
            équipe d’architectes en interne, pour garantir la meilleure
            habitabilité.
          </p>
          <p>
            Nos nouveaux projets de logement bénéficient également d’une palette
            élargie de typologies d’appartements (typologies intermédiaires ou
            alcôve), avec pour chacune d'elles plusieurs possibilités
            d'aménagements intérieurs permettant de s’adapter aux styles de vie
            de chacun.
          </p>
          <p class="excerpt">
            <strong>200 options</strong> de personnalisation
          </p>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-4-visuel1.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}

function EngaRow2() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>Des logements adaptés au télétravail</h3>
          <p>
            Les architectes internes de la Direction Produit de Cogedim sont les
            garants de l’habitabilité de chaque appartement.
          </p>
          <p>
            Nos nouveaux projets de logement bénéficient également d’une palette
            élargie de typologies d’appartements (typologies intermédiaires ou
            alcôve), avec pour chacune d’entre elles, plusieurs possibilités
            d’aménagements intérieurs (200 options de personnalisation).
          </p>
          <p>
            Chaque appartement Cogedim à partir du 2P est conçu pour accueillir
            un ou deux espaces de télétravail selon sa superficie. Des options
            seront proposées à chacun afin d’aménager au mieux ces espaces
            (verrière,...).
          </p>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-4-visuel2.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}

function VideoExcerpt() {
  const [, { setMediaOverlay }] = useMediaOverlayContext();

  return (
    <>
      <section class="video-excerpt">
        <div class="video-row">
          <div class="visual">
            <img
              src={imgProxy(
                "/images/engagements/page-engagement-4-excerpt-video.jpg",
                `size:675:480/resizing_type:fill`,
              )}
              height="267"
              width="375"
              alt=""
            />
            <button
              class="btn btn-icon"
              onClick={() => {
                setMediaOverlay(
                  "embed",
                  `<iframe height="100%" width="100%" src="https://www.youtube.com/embed/txNZeMukHmA" allowfullscreen />`,
                );
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconPlayArrow />
              </i>
            </button>
          </div>
          <div class="video-details">
            <h4>Un appartement bien agencé compte triple</h4>
            <p>
              Découvrez comment Cogedim optimise chaque m<sup>2</sup> de tous
              ses appartements.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
